import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-component';
import { Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { toggleArray, campusLogos, cloneDeep } from 'components/modules/_misc';
import { create_proactive } from 'components/modules/ada';
import { DEFAULT } from 'components/modules/app';
import { DIRECT_ADMIT_CAMPUS_TIERS, CAMPUS_TITLES } from 'components/modules/campus';
import { getDirectAdmitTerm } from 'components/modules/term';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';
import Button from 'components/CustomButton';
import { UserActions } from 'reducers/user';

const masonryOptions = {
	transitionDuration: 1000,
	fitWidth: true,
	isOriginLeft: true
};

class DirectAdmitSelectCampus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected_campuses: Object.keys(props.app.initial_information.direct_admit),
			allowed: true
		};
	}

	componentDidMount() {
		const { app, direct_admit, terms, updateApp } = this.props;

		if (direct_admit?.user_id) {
			let initial_information = cloneDeep(app.initial_information),
				term = getDirectAdmitTerm(terms, direct_admit);

			if (term) {
				this._mounted = true;
				this.direct_admit_orig = cloneDeep(app.initial_information.direct_admit);

				if (app.application_modifier !== 'DA') {
					initial_information.chosen_semester = {
						id: term.id,
						title: term.title,
						global_term: term.global_term
					};

					updateApp({ application_modifier: 'DA', initial_information: initial_information });
				}

				setTimeout(() => {
					if (this._mounted) create_proactive('Not sure what campus to pick?', '5ebae1b067c1c5e986c86ef8');
				}, 2000);
			} else {
				this.setState({ allowed: false });
			}
		} else {
			this.setState({ allowed: false });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { app, updateApp } = this.props,
			{ selected_campuses } = this.state;

		if (selected_campuses.length != prevState.selected_campuses.length) {
			let _selected_campuses = cloneDeep(selected_campuses),
				initial_information = cloneDeep(app.initial_information);

			_selected_campuses.sort(
				(a, b) => Object.keys(CAMPUS_TITLES).indexOf(a) - Object.keys(CAMPUS_TITLES).indexOf(b)
			);

			initial_information.direct_admit = {};

			_selected_campuses.forEach(code => {
				initial_information.direct_admit[code] =
					this.direct_admit_orig[code] || cloneDeep(DEFAULT.APP_JSON.area_of_study);
			});

			updateApp({ initial_information: initial_information });
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	allowCampus = c => {
		const { direct_admit } = this.props,
			{ tier } = direct_admit,
			applicant_tier = Number(tier.substr(1)),
			campus_tier = DIRECT_ADMIT_CAMPUS_TIERS.findIndex(arrTier => arrTier.includes(c.field_abbreviation)) + 1,
			tier_met = !!campus_tier && applicant_tier >= campus_tier;

		if (tier_met && !c.title.includes('Flex')) return true;
	};

	saveAndContinue = e => {
		let { navigate, setScreensCompleted } = this.props;

		e.preventDefault();
		e.stopPropagation();

		setScreensCompleted({ selectCampusCompleted: true });
		navigate('/course-of-study');
	};

	renderNotAllowModal = () => {
		const { navigate, direct_admit } = this.props,
			{ allowed } = this.state,
			msg = direct_admit?.user_id
				? `We show your graduation year to be ${direct_admit.graduation_year}, but that term doesn't seem to be open right now.`
				: "We can't find the Direct Admit record from your high school.  Have you linked it to your account yet?";

		return (
			<Modal show={!allowed}>
				<Modal.Header>
					<Modal.Title className='h2'>Something's Wrong</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>{msg}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='save-btn' fill onClick={() => navigate('/my-account')}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderSelectionPage = () => {
		let { campuses, apps, direct_admit } = this.props,
			{ selected_campuses } = this.state,
			disabled_campus_names = Object.keys(apps)
				.filter(_app_id => {
					const { app_type, term, date_submitted } = apps[_app_id],
						direct_admit_submitted =
							app_type === 'DA' &&
							term === `Direct Admit ${direct_admit.graduation_year}` &&
							!!date_submitted,
						trad_app_exists =
							['FM_D', 'TR_D'].includes(app_type) &&
							term.includes(`Fall ${direct_admit.graduation_year}`);

					return direct_admit_submitted || trad_app_exists;
				})
				.map(_app_id => apps[_app_id].campus),
			parentCards = [];

		campuses.forEach(campus => {
			if (!campus.field_parent_campus) {
				let img = campusLogos[campus.field_abbreviation],
					region_campuses = [campus],
					child_campuses = campuses.filter(
						c => c.field_parent_campus === campus.id && !c.title.includes('Flex')
					);

				region_campuses = region_campuses.concat(child_campuses).filter(c => this.allowCampus(c));

				if (region_campuses.some(c => !c.disabled)) {
					parentCards.push(
						<div className='da-campusCard col-md-2' key={`${campus.field_abbreviation}-card`}>
							<div className='img-wrapper'>
								<img src={img} width={121} alt={`${campus.title} campus logo`} />
							</div>

							{region_campuses.map(c => (
								<input
									key={c.field_abbreviation}
									type='button'
									disabled={disabled_campus_names.includes(c.title)}
									className={selected_campuses.includes(c.field_abbreviation) ? 'selected' : ''}
									onClick={e =>
										this.setState({
											selected_campuses: toggleArray(selected_campuses, c.field_abbreviation)
										})
									}
									value={c.title}
								/>
							))}
						</div>
					);
				}
			}
		});

		return (
			<div className='text-center campus-selector'>
				<Masonry options={masonryOptions} className='col-centered'>
					{parentCards}
				</Masonry>
			</div>
		);
	};

	render() {
		let { navigate, app_id } = this.props,
			{ selected_campuses } = this.state;

		return (
			<NoSidebarCard
				toAccount={() => navigate('/my-account')}
				fullWidth={true}
				header='What campuses would you like to connect with?'
				content={
					<>
						{this.renderNotAllowModal()}

						<p className='da-campus'>You have the following Direct Admit offers.</p>
						<p className='da-campus'>Select all UWs you would like to send your Direct Admit form to.</p>
						<form id='form' className='campus-form' onSubmit={this.saveAndContinue}>
							{this.renderSelectionPage()}
						</form>

						{!app_id && (
							<p className='da-campus'>
								To apply to a campus not on this list,{' '}
								<a onClick={() => navigate('/select-modifier', { state: { from_direct_admit: true } })}>
									start an application
								</a>
								.
							</p>
						)}
					</>
				}
				buttons={
					<>
						<Button bsStyle='info' className='tertiaryButton' fill onClick={() => navigate('/my-account')}>
							My Account
						</Button>

						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!selected_campuses.length}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		apps: state.user.apps,
		app: state.user.temp_app_rec.json_obj,
		app_id: state.user.app_id,
		direct_admit: state.user.direct_admit,
		campuses: state.global.campuses.filter(c => c.field_abbreviation !== 'RLN'),
		terms: state.global.terms
	}),
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectAdmitSelectCampus));
