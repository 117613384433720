export const countries = [
		{ val: 'Other', label: 'Other' },
		{ val: 'USA', label: 'United States' },
		{
			val: 'AFG',
			label: 'Afghanistan'
		},
		{
			val: 'ALA',
			label: 'Aland Islands'
		},
		{
			val: 'ALB',
			label: 'Albania'
		},
		{
			val: 'DZA',
			label: 'Algeria'
		},
		{
			val: 'AND',
			label: 'Andorra'
		},
		{
			val: 'AGO',
			label: 'Angola'
		},
		{
			val: 'AIA',
			label: 'Anguilla'
		},
		{
			val: 'ATA',
			label: 'Antarctica'
		},
		{
			val: 'ATG',
			label: 'Antigua and Barbuda'
		},
		{
			val: 'ARG',
			label: 'Argentina'
		},
		{
			val: 'ARM',
			label: 'Armenia'
		},
		{
			val: 'ABW',
			label: 'Aruba'
		},
		{
			val: 'AUS',
			label: 'Australia'
		},
		{
			val: 'AUT',
			label: 'Austria'
		},
		{
			val: 'AZE',
			label: 'Azerbaijan'
		},
		{
			val: 'BHS',
			label: 'Bahamas'
		},
		{
			val: 'BHR',
			label: 'Bahrain'
		},
		{
			val: 'BGD',
			label: 'Bangladesh'
		},
		{
			val: 'BRB',
			label: 'Barbados'
		},
		{
			val: 'BLR',
			label: 'Belarus'
		},
		{
			val: 'BEL',
			label: 'Belgium'
		},
		{
			val: 'BLZ',
			label: 'Belize'
		},
		{
			val: 'BEN',
			label: 'Benin'
		},
		{
			val: 'BMU',
			label: 'Bermuda'
		},
		{
			val: 'BTN',
			label: 'Bhutan'
		},
		{
			val: 'BOL',
			label: 'Bolivia'
		},
		{
			val: 'BIH',
			label: 'Bosnia and Herzegovina'
		},
		{
			val: 'BWA',
			label: 'Botswana'
		},
		{
			val: 'BVT',
			label: 'Bouvet Island'
		},
		{
			val: 'BRA',
			label: 'Brazil'
		},
		{
			val: 'IOT',
			label: 'British Indian Ocean Territory'
		},
		{
			val: 'BRN',
			label: 'Brunei Darussalam'
		},
		{
			val: 'BGR',
			label: 'Bulgaria'
		},
		{
			val: 'BFA',
			label: 'Burkina Faso'
		},
		{
			val: 'BDI',
			label: 'Burundi'
		},
		{
			val: 'KHM',
			label: 'Cambodia'
		},
		{
			val: 'CMR',
			label: 'Cameroon'
		},
		{
			val: 'CAN',
			label: 'Canada'
		},
		{
			val: 'CPV',
			label: 'Cape Verde'
		},
		{
			val: 'CYM',
			label: 'Cayman Islands'
		},
		{
			val: 'CAF',
			label: 'Central African Republic'
		},
		{
			val: 'TCD',
			label: 'Chad'
		},
		{
			val: 'CHL',
			label: 'Chile'
		},
		{
			val: 'CHN',
			label: 'China'
		},
		{
			val: 'CXR',
			label: 'Christmas Island'
		},
		{
			val: 'CCK',
			label: 'Cocos (Keeling) Islands'
		},
		{
			val: 'COL',
			label: 'Colombia'
		},
		{
			val: 'COM',
			label: 'Comoros'
		},
		{
			val: 'COG',
			label: 'Congo'
		},
		{
			val: 'COD',
			label: 'Congo, Democratic Republic of the'
		},
		{
			val: 'COK',
			label: 'Cook Islands'
		},
		{
			val: 'CRI',
			label: 'Costa Rica'
		},
		{
			val: 'CIV',
			label: "Cote d'Ivoire"
		},
		{
			val: 'HRV',
			label: 'Croatia'
		},
		{
			val: 'CUB',
			label: 'Cuba'
		},
		{
			val: 'CYP',
			label: 'Cyprus'
		},
		{
			val: 'CZE',
			label: 'Czech Republic'
		},
		{
			val: 'DNK',
			label: 'Denmark'
		},
		{
			val: 'DJI',
			label: 'Djibouti'
		},
		{
			val: 'DMA',
			label: 'Dominica'
		},
		{
			val: 'DOM',
			label: 'Dominican Republic'
		},
		{
			val: 'ECU',
			label: 'Ecuador'
		},
		{
			val: 'EGY',
			label: 'Egypt'
		},
		{
			val: 'SLV',
			label: 'El Salvador'
		},
		{
			val: 'GNQ',
			label: 'Equatorial Guinea'
		},
		{
			val: 'ERI',
			label: 'Eritrea'
		},
		{
			val: 'EST',
			label: 'Estonia'
		},
		{
			val: 'SWZ',
			label: 'Eswatini'
		},
		{
			val: 'ETH',
			label: 'Ethiopia'
		},
		{
			val: 'FLK',
			label: 'Falkland Islands (Malvinas)'
		},
		{
			val: 'FRO',
			label: 'Faroe Islands'
		},
		{
			val: 'FJI',
			label: 'Fiji'
		},
		{
			val: 'FIN',
			label: 'Finland'
		},
		{
			val: 'FRA',
			label: 'France'
		},
		{
			val: 'GUF',
			label: 'French Guiana'
		},
		{
			val: 'PYF',
			label: 'French Polynesia'
		},
		{
			val: 'ATF',
			label: 'French Southern Territories'
		},
		{
			val: 'GAB',
			label: 'Gabon'
		},
		{
			val: 'GMB',
			label: 'Gambia'
		},
		{
			val: 'GEO',
			label: 'Georgia'
		},
		{
			val: 'DEU',
			label: 'Germany'
		},
		{
			val: 'GHA',
			label: 'Ghana'
		},
		{
			val: 'GIB',
			label: 'Gibraltar'
		},
		{
			val: 'GRC',
			label: 'Greece'
		},
		{
			val: 'GRL',
			label: 'Greenland'
		},
		{
			val: 'GRD',
			label: 'Grenada'
		},
		{
			val: 'GLP',
			label: 'Guadeloupe'
		},
		{
			val: 'GTM',
			label: 'Guatemala'
		},
		{
			val: 'GGY',
			label: 'Guernsey'
		},
		{
			val: 'GIN',
			label: 'Guinea'
		},
		{
			val: 'GNB',
			label: 'Guinea-Bissau'
		},
		{
			val: 'GUY',
			label: 'Guyana'
		},
		{
			val: 'HTI',
			label: 'Haiti'
		},
		{
			val: 'HMD',
			label: 'Heard Island and McDonald Islands'
		},
		{
			val: 'VAT',
			label: 'Holy See (Vatican City State)'
		},
		{
			val: 'HND',
			label: 'Honduras'
		},
		{
			val: 'HKG',
			label: 'Hong Kong'
		},
		{
			val: 'HUN',
			label: 'Hungary'
		},
		{
			val: 'ISL',
			label: 'Iceland'
		},
		{
			val: 'IND',
			label: 'India'
		},
		{
			val: 'IDN',
			label: 'Indonesia'
		},
		{
			val: 'IRN',
			label: 'Iran'
		},
		{
			val: 'IRQ',
			label: 'Iraq'
		},
		{
			val: 'IRL',
			label: 'Ireland'
		},
		{
			val: 'IMN',
			label: 'Isle of Man'
		},
		{
			val: 'ISR',
			label: 'Israel'
		},
		{
			val: 'ITA',
			label: 'Italy'
		},
		{
			val: 'JAM',
			label: 'Jamaica'
		},
		{
			val: 'JPN',
			label: 'Japan'
		},
		{
			val: 'JEY',
			label: 'Jersey'
		},
		{
			val: 'JOR',
			label: 'Jordan'
		},
		{
			val: 'KAZ',
			label: 'Kazakhstan'
		},
		{
			val: 'KEN',
			label: 'Kenya'
		},
		{
			val: 'KIR',
			label: 'Kiribati'
		},
		{
			val: 'PRK',
			label: "Korea, Democratic People's Republic"
		},
		{
			val: 'KOR',
			label: 'Korea, Republic of'
		},
		{
			val: 'KWT',
			label: 'Kuwait'
		},
		{
			val: 'KGZ',
			label: 'Kyrgyzstan'
		},
		{
			val: 'LAO',
			label: "Lao People's Democratic Republic"
		},
		{
			val: 'LVA',
			label: 'Latvia'
		},
		{
			val: 'LBN',
			label: 'Lebanon'
		},
		{
			val: 'LSO',
			label: 'Lesotho'
		},
		{
			val: 'LBR',
			label: 'Liberia'
		},
		{
			val: 'LBY',
			label: 'Libyan Arab Jamahiriya'
		},
		{
			val: 'LIE',
			label: 'Liechtenstein'
		},
		{
			val: 'LTU',
			label: 'Lithuania'
		},
		{
			val: 'LUX',
			label: 'Luxembourg'
		},
		{
			val: 'MAC',
			label: 'Macao'
		},
		{
			val: 'MKD',
			label: 'Macedonia'
		},
		{
			val: 'MDG',
			label: 'Madagascar'
		},
		{
			val: 'MWI',
			label: 'Malawi'
		},
		{
			val: 'MYS',
			label: 'Malaysia'
		},
		{
			val: 'MDV',
			label: 'Maldives'
		},
		{
			val: 'MLI',
			label: 'Mali'
		},
		{
			val: 'MLT',
			label: 'Malta'
		},
		{
			val: 'MHL',
			label: 'Marshall Islands'
		},
		{
			val: 'MTQ',
			label: 'Martinique'
		},
		{
			val: 'MRT',
			label: 'Mauritania'
		},
		{
			val: 'MUS',
			label: 'Mauritius'
		},
		{
			val: 'MYT',
			label: 'Mayotte'
		},
		{
			val: 'MEX',
			label: 'Mexico'
		},
		{
			val: 'FSM',
			label: 'Micronesia, Federated States of'
		},
		{
			val: 'MDA',
			label: 'Moldova, Republic of'
		},
		{
			val: 'MCO',
			label: 'Monaco'
		},
		{
			val: 'MNG',
			label: 'Mongolia'
		},
		{
			val: 'MNE',
			label: 'Montenegro'
		},
		{
			val: 'MSR',
			label: 'Montserrat'
		},
		{
			val: 'MAR',
			label: 'Morocco'
		},
		{
			val: 'MOZ',
			label: 'Mozambique'
		},
		{
			val: 'MMR',
			label: 'Myanmar'
		},
		{
			val: 'NAM',
			label: 'Namibia'
		},
		{
			val: 'NRU',
			label: 'Nauru'
		},
		{
			val: 'NPL',
			label: 'Nepal'
		},
		{
			val: 'NLD',
			label: 'Netherlands'
		},
		{
			val: 'ANT',
			label: 'Netherlands Antilles'
		},
		{
			val: 'NCL',
			label: 'New Caledonia'
		},
		{
			val: 'NZL',
			label: 'New Zealand'
		},
		{
			val: 'NIC',
			label: 'Nicaragua'
		},
		{
			val: 'NER',
			label: 'Niger'
		},
		{
			val: 'NGA',
			label: 'Nigeria'
		},
		{
			val: 'NIU',
			label: 'Niue'
		},
		{
			val: 'NFK',
			label: 'Norfolk Island'
		},
		{
			val: 'NOR',
			label: 'Norway'
		},
		{
			val: 'OMN',
			label: 'Oman'
		},
		{
			val: 'PAK',
			label: 'Pakistan'
		},
		{
			val: 'PLW',
			label: 'Palau'
		},
		{
			val: 'PSE',
			label: 'Palestinian Territory, Occupied'
		},
		{
			val: 'PAN',
			label: 'Panama'
		},
		{
			val: 'PNG',
			label: 'Papua New Guinea'
		},
		{
			val: 'PRY',
			label: 'Paraguay'
		},
		{
			val: 'PER',
			label: 'Peru'
		},
		{
			val: 'PHL',
			label: 'Philippines'
		},
		{
			val: 'PCN',
			label: 'Pitcairn'
		},
		{
			val: 'POL',
			label: 'Poland'
		},
		{
			val: 'PRT',
			label: 'Portugal'
		},
		{
			val: 'QAT',
			label: 'Qatar'
		},
		{
			val: 'REU',
			label: 'Reunion'
		},
		{
			val: 'ROU',
			label: 'Romania'
		},
		{
			val: 'RUS',
			label: 'Russia'
		},
		{
			val: 'RWA',
			label: 'Rwanda'
		},
		{
			val: 'SHN',
			label: 'Saint Helena'
		},
		{
			val: 'KNA',
			label: 'Saint Kitts and Nevis'
		},
		{
			val: 'LCA',
			label: 'Saint Lucia'
		},
		{
			val: 'SPM',
			label: 'Saint Pierre and Miquelon'
		},
		{
			val: 'VCT',
			label: 'Saint Vincent and the Grenadines'
		},
		{
			val: 'WSM',
			label: 'Samoa'
		},
		{
			val: 'SMR',
			label: 'San Marino'
		},
		{
			val: 'STP',
			label: 'Sao Tome and Principe'
		},
		{
			val: 'SAU',
			label: 'Saudi Arabia'
		},
		{
			val: 'SEN',
			label: 'Senegal'
		},
		{
			val: 'SRB',
			label: 'Serbia'
		},
		{
			val: 'SYC',
			label: 'Seychelles'
		},
		{
			val: 'SLE',
			label: 'Sierra Leone'
		},
		{
			val: 'SGP',
			label: 'Singapore'
		},
		{
			val: 'SVK',
			label: 'Slovakia'
		},
		{
			val: 'SVN',
			label: 'Slovenia'
		},
		{
			val: 'SLB',
			label: 'Solomon Islands'
		},
		{
			val: 'SOM',
			label: 'Somalia'
		},
		{
			val: 'ZAF',
			label: 'South Africa'
		},
		{
			val: 'SGS',
			label: 'South Georgia/So. Sandwich Islands'
		},
		{
			val: 'SSD',
			label: 'South Sudan'
		},
		{
			val: 'ESP',
			label: 'Spain'
		},
		{
			val: 'LKA',
			label: 'Sri Lanka'
		},
		{
			val: 'SDN',
			label: 'Sudan'
		},
		{
			val: 'SUR',
			label: 'Suriname'
		},
		{
			val: 'SJM',
			label: 'Svalbard and Jan Mayen'
		},
		{
			val: 'SWE',
			label: 'Sweden'
		},
		{
			val: 'CHE',
			label: 'Switzerland'
		},
		{
			val: 'SYR',
			label: 'Syrian Arab Republic'
		},
		{
			val: 'TWN',
			label: 'Taiwan'
		},
		{
			val: 'TJK',
			label: 'Tajikistan'
		},
		{
			val: 'TZA',
			label: 'Tanzania'
		},
		{
			val: 'THA',
			label: 'Thailand'
		},
		{
			val: 'TLS',
			label: 'Timor-Leste'
		},
		{
			val: 'TGO',
			label: 'Togo'
		},
		{
			val: 'TKL',
			label: 'Tokelau'
		},
		{
			val: 'TON',
			label: 'Tonga'
		},
		{
			val: 'TTO',
			label: 'Trinidad and Tobago'
		},
		{
			val: 'TUN',
			label: 'Tunisia'
		},
		{
			val: 'TUR',
			label: 'Turkey'
		},
		{
			val: 'TKM',
			label: 'Turkmenistan'
		},
		{
			val: 'TCA',
			label: 'Turks and Caicos Islands'
		},
		{
			val: 'TUV',
			label: 'Tuvalu'
		},
		{
			val: 'UMI',
			label: 'U.S. Minor Outlying Islands'
		},
		{
			val: 'UGA',
			label: 'Uganda'
		},
		{
			val: 'UKR',
			label: 'Ukraine'
		},
		{
			val: 'ARE',
			label: 'United Arab Emirates'
		},
		{
			val: 'GBR',
			label: 'United Kingdom'
		},
		{
			val: 'USA',
			label: 'United States'
		},
		{
			val: 'URY',
			label: 'Uruguay'
		},
		{
			val: 'UZB',
			label: 'Uzbekistan'
		},
		{
			val: 'VUT',
			label: 'Vanuatu'
		},
		{
			val: 'VEN',
			label: 'Venezuela'
		},
		{
			val: 'VNM',
			label: 'Viet Nam'
		},
		{
			val: 'VGB',
			label: 'Virgin Islands, British'
		},
		{
			val: 'WLF',
			label: 'Wallis and Futuna'
		},
		{
			val: 'ESH',
			label: 'Western Sahara'
		},
		{
			val: 'YEM',
			label: 'Yemen'
		},
		{
			val: 'YUG',
			label: 'Yugoslavia'
		},
		{
			val: 'ZMB',
			label: 'Zambia'
		},
		{
			val: 'ZWE',
			label: 'Zimbabwe'
		}
	],
	states = [
		{ val: 'WI', label: 'Wisconsin' },

		{ val: 'AK', label: 'Alaska' },
		{ val: 'AL', label: 'Alabama' },
		{ val: 'AR', label: 'Arkansas' },
		{ val: 'AZ', label: 'Arizona' },
		{ val: 'CA', label: 'California' },
		{ val: 'CO', label: 'Colorado' },
		{ val: 'CT', label: 'Connecticut' },
		{ val: 'DC', label: 'District of Columbia' },
		{ val: 'DE', label: 'Delaware' },
		{ val: 'FL', label: 'Florida' },
		{ val: 'GA', label: 'Georgia' },
		{ val: 'HI', label: 'Hawaii' },
		{ val: 'IA', label: 'Iowa' },
		{ val: 'ID', label: 'Idaho' },
		{ val: 'IL', label: 'Illinois' },
		{ val: 'IN', label: 'Indiana' },
		{ val: 'KS', label: 'Kansas' },
		{ val: 'KY', label: 'Kentucky' },
		{ val: 'LA', label: 'Louisiana' },
		{ val: 'MA', label: 'Massachusetts' },
		{ val: 'MD', label: 'Maryland' },
		{ val: 'ME', label: 'Maine' },
		{ val: 'MI', label: 'Michigan' },
		{ val: 'MN', label: 'Minnesota' },
		{ val: 'MO', label: 'Missouri' },
		{ val: 'MS', label: 'Mississippi' },
		{ val: 'MT', label: 'Montana' },
		{ val: 'NC', label: 'North Carolina' },
		{ val: 'ND', label: 'North Dakota' },
		{ val: 'NE', label: 'Nebraska' },
		{ val: 'NH', label: 'New Hampshire' },
		{ val: 'NJ', label: 'New Jersey' },
		{ val: 'NM', label: 'New Mexico' },
		{ val: 'NV', label: 'Nevada' },
		{ val: 'NY', label: 'New York' },
		{ val: 'OH', label: 'Ohio' },
		{ val: 'OK', label: 'Oklahoma' },
		{ val: 'OR', label: 'Oregon' },
		{ val: 'PA', label: 'Pennsylvania' },
		{ val: 'RI', label: 'Rhode Island' },
		{ val: 'SC', label: 'South Carolina' },
		{ val: 'SD', label: 'South Dakota' },
		{ val: 'TN', label: 'Tennessee' },
		{ val: 'TX', label: 'Texas' },
		{ val: 'UT', label: 'Utah' },
		{ val: 'VA', label: 'Virginia' },
		{ val: 'VT', label: 'Vermont' },
		{ val: 'WA', label: 'Washington' },
		{ val: 'WV', label: 'West Virginia' },
		{ val: 'WY', label: 'Wyoming' },

		{ val: 'AS', label: 'American Samoa' },
		{ val: 'GU', label: 'Guam' },
		{ val: 'MP', label: 'Northern Mariana Islands' },
		{ val: 'PR', label: 'Puerto Rico' },
		{ val: 'TT', label: 'Trust Territories' },
		{ val: 'VI', label: 'Virgin Islands (U.S.)' },
		{ val: 'ZZ', label: 'Other US Territory' },

		{ val: 'AA', label: 'Armed Forces-Americas (not Canada)' },
		{ val: 'AP', label: 'Armed Forces-Pacific' },
		{ val: 'AE', label: 'Armed Forces-Other' }
	],
	tribes = [
		{ val: 'ALEUT', label: 'Aleut (Unangan)' },
		{ val: 'APCHIRIC', label: 'Apache/Chiricahua Nation' },
		{ val: 'APFTMCD', label: 'Apache/Fort McDowell Yavapai' },
		{ val: 'APFTSILL', label: 'Apache/Fort Sill Chiricahua' },
		{ val: 'APJICARI', label: 'Apache/Jicarilla Nation' },
		{ val: 'APMESCAL', label: 'Apache/Mescalero Nation' },
		{ val: 'APPLAINS', label: 'Apache/Plains' },
		{ val: 'APSANCAR', label: 'Apache/San Carlos' },
		{ val: 'APTONTO', label: 'Apache/Tonto Nation' },
		{ val: 'APWHITMT', label: 'Apache/White Mountain Nation' },
		{ val: 'APYAVAPA', label: 'Apache/Yavapai Nation' },
		{ val: 'ARAPAHON', label: 'Arapaho/Northern' },
		{ val: 'ARAPAHOS', label: 'Arapaho/Southern' },
		{ val: 'ATHABSKN', label: 'Alaskan Athabascan' },
		{ val: 'BLKFOOT', label: 'Blackfeet/Blackfeet Nation' },
		{ val: 'BROTHRTN', label: 'Brotherton/WI Brothern Tribe' },
		{ val: 'CAYUGA', label: 'Iroquois/Cayuga' },
		{ val: 'CHEROK', label: 'Cherokee/Cherokee Nation' },
		{ val: 'CHEROKEA', label: 'Cherokee/Eastern Band' },
		{ val: 'CHEROKUN', label: 'Cherokee/United Keetoowah Band' },
		{ val: 'CHEYENNO', label: 'Cheyenne/Northern' },
		{ val: 'CHEYENSO', label: 'Cheyenne/Southern ' },
		{ val: 'CHICKSA', label: 'Chickasaw' },
		{ val: 'CHIPPBR', label: 'Chippewa/WI Bad River' },
		{ val: 'CHIPPEW', label: 'Chippewa, Ojibwe/Non-WI,MI,MN' },
		{ val: 'CHIPPLCO', label: 'Chippewa/WI Lac Courte Oreille' },
		{ val: 'CHIPPLDF', label: 'Chippewa/WI Lac du Flambeau' },
		{ val: 'CHIPPMIB', label: 'Chippewa/MI Bay Mills' },
		{ val: 'CHIPPMIK', label: 'Chippewa/MI Keweenaw Bay' },
		{ val: 'CHIPPMIL', label: 'Chippewa/MI Lac Vieux Desert' },
		{ val: 'CHIPPMIS', label: 'Chippewa/MI Saginaw' },
		{ val: 'CHIPPMIT', label: 'Chippewa/MI Sault Ste. Marie' },
		{ val: 'CHIPPMNB', label: 'Chippewa/MN Bois Forte Band' },
		{ val: 'CHIPPMNF', label: 'Chippewa/MN Fond du Lac Band' },
		{ val: 'CHIPPMNG', label: 'Chippewa/MN Grand Portage Band' },
		{ val: 'CHIPPMNL', label: 'Chippewa/MN Leech Lake Band' },
		{ val: 'CHIPPMNM', label: 'Chippewa/MN Mille Lacs Band' },
		{ val: 'CHIPPMNR', label: 'Chippewa/MN Red Lake Band' },
		{ val: 'CHIPPMNW', label: 'Chippewa/MN White Earth Band' },
		{ val: 'CHIPPRCB', label: 'Chippewa/WI Red Cliff Band' },
		{ val: 'CHIPPSC', label: 'Chippewa/WI St Croix' },
		{ val: 'CHIPPSML', label: 'Chippewa/WI Sokaogan-Mole Lake' },
		{ val: 'CHOCTLA', label: 'Choctaw/LA Jena Band' },
		{ val: 'CHOCTMS', label: 'Choctaw/MS Band' },
		{ val: 'CHOCTOK', label: 'Choctaw/OK' },
		{ val: 'COLVILLE', label: 'Colville' },
		{ val: 'COMANCH', label: 'Comanche' },
		{ val: 'COUSHLAC', label: 'Coushatta/LA Coushatta Tribe' },
		{ val: 'COUSHOKA', label: 'Coushatta/OK Alabama-Quassarte Tribal Town' },
		{ val: 'COUSHTXA', label: 'Coushatta/TX Alabama-Coushatta Tribe' },
		{ val: 'CREEKAL', label: 'Creek/AL Poarch Band' },
		{ val: 'CREEKOK', label: 'Creek/OK Muscogee' },
		{ val: 'CROW', label: 'Crow (Apsáalooke)' },
		{ val: 'DAKOTAMN', label: 'Dakota/MN Prairie Island' },
		{ val: 'DAKOTASI', label: 'Dakota (Sioux)/Sisseton Wahpet' },
		{ val: 'DELAWARE', label: 'Delaware (Lenape)/Eastern' },
		{ val: 'DELAWARW', label: 'Delaware (Lenape)/Western' },
		{ val: 'ESKIMO', label: 'Eskimo/Yupik' },
		{ val: 'HOCHUNK', label: 'Ho-Chunk/WI' },
		{ val: 'HOPI', label: 'Hopi' },
		{ val: 'HURON', label: 'Huron (Wyandotte)' },
		{ val: 'INUIT', label: 'Eskimo/Inuit' },
		{ val: 'IROQMOHA', label: 'Iroquois/Mohawk' },
		{ val: 'IROQTUSC', label: 'Iroquois/Tuscarora' },
		{ val: 'KICKAPKA', label: 'Kickapoo/Kansas' },
		{ val: 'KICKAPOK', label: 'Kickapoo/Oklahoma ' },
		{ val: 'KICKAPTX', label: 'Kickapoo/Texas' },
		{ val: 'KIOWA', label: 'Kiowa' },
		{ val: 'LUMBEE', label: 'Lumbee' },
		{ val: 'MENOMIN', label: 'Menominee/WI ' },
		{ val: 'MICCOSUK', label: 'Miccosukee ' },
		{ val: 'MOHICAN', label: 'Mohican/Non-WI' },
		{ val: 'MOHICSTM', label: 'Mohican/WI Stockbridge-Munsee' },
		{ val: 'NAVAJO', label: "Navajo (Dine')" },
		{ val: 'NIPMUCMA', label: 'Nipmuc/Massachusetts' },
		{ val: 'ODAWLTB', label: 'Odawa/MI Little Traverse Bay' },
		{ val: 'ONEIDA', label: 'Iroquois/NY Oneida' },
		{ val: 'ONEIDAWI', label: 'Iroquois/WI Oneida' },
		{ val: 'ONONDAG', label: 'Iroquois/Onondaga' },
		{ val: 'OSAGE', label: 'Osage' },
		{ val: 'OTTAWGTB', label: 'Ottawa&Chippewa/MI Grand Travr' },
		{ val: 'OTTAWLRB', label: 'Ottawa/MI Little River Band' },
		{ val: 'OTTAWNON', label: 'Ottawa or Odawa/Non-MI' },
		{ val: 'PAIUTENO', label: 'Paiute/Northern' },
		{ val: 'PAIUTEOW', label: 'Paiute/Owens Valley' },
		{ val: 'PAIUTESO', label: 'Paiute/Southern ' },
		{ val: 'PIMA', label: "Pima/Akimel O'odham & Maricopa" },
		{ val: 'POMO', label: 'Pomo' },
		{ val: 'POTAWAFC', label: 'Potawatomi/WI Forest County' },
		{ val: 'POTAWAHA', label: 'Potawatomi/MI Hannahville' },
		{ val: 'POTAWAKS', label: 'Potawatomi/KS Prarie Band' },
		{ val: 'POTAWAMA', label: 'Potawatomi/MI Match-e-be-nash-' },
		{ val: 'POTAWANO', label: 'Potawatomi/MI Nottawaseppi Hur' },
		{ val: 'POTAWAOK', label: 'Potawatomi/OK Citizen Nation' },
		{ val: 'POTAWAPO', label: 'Potawatomi/MI Pokagon Band' },
		{ val: 'PUEBLO', label: 'Pueblo' },
		{ val: 'PUGETSAL', label: 'Puget Salish' },
		{ val: 'QUECHAN', label: 'Quechan (Yuma)' },
		{ val: 'SACFOXIA', label: 'Sac&Fox/IA Mississippi Tribe' },
		{ val: 'SACFOXKS', label: 'Sac&Fox/KS&NB Missouri Tribe' },
		{ val: 'SACFOXOK', label: 'Sac&Fox Nation/OK' },
		{ val: 'SEMINOFA', label: 'Seminole/Florida' },
		{ val: 'SEMINOOK', label: 'Seminole/Oklahoma' },
		{ val: 'SENECA', label: 'Iroquois/Seneca' },
		{ val: 'SHAWNEE', label: 'Shawnee/Shawnee Tribe' },
		{ val: 'SHAWNEEA', label: 'Shawnee/Absentee-Shawnee' },
		{ val: 'SHAWNEEE', label: 'Shawnee/Eastern-Shawnee' },
		{ val: 'SHOSHONE', label: 'Shoshone/Eastern' },
		{ val: 'SHOSHONN', label: 'Shoshone/Northern' },
		{ val: 'SHOSHONW', label: 'Shoshone/Western' },
		{ val: 'SIOUXCHR', label: 'Sioux/Cheyenne River' },
		{ val: 'SIOUXCRC', label: 'Sioux/Crow Creek' },
		{ val: 'SIOUXFLS', label: 'Sioux/Flandreau Santee' },
		{ val: 'SIOUXFTP', label: 'Sioux & Assiniboine/Fort Peck' },
		{ val: 'SIOUXLBR', label: 'Sioux/Lower Brule' },
		{ val: 'SIOUXMNL', label: 'Sioux/MN Lower' },
		{ val: 'SIOUXMNS', label: 'Sioux/MN Shakopee Mdewakanton' },
		{ val: 'SIOUXMNU', label: 'Sioux/MN Upper' },
		{ val: 'SIOUXPRI', label: 'Sioux/Pine Ridge-Oglala Lakota' },
		{ val: 'SIOUXROS', label: 'Sioux/Rosebud' },
		{ val: 'SIOUXSAN', label: 'Sioux/Santee' },
		{ val: 'SIOUXSPL', label: 'Sioux/Spirit Lake' },
		{ val: 'SIOUXSTR', label: 'Sioux/Standing Rock' },
		{ val: 'SIOUXYAN', label: 'Sioux/Yankton ' },
		{ val: 'TLINGIT', label: 'Tlingit-Haida' },
		{ val: 'TOHONO', label: "Tohono O'odham" },
		{ val: 'UNITEDHO', label: 'United Houma' },
		{ val: 'UTE', label: 'Ute/Ute Mountain' },
		{ val: 'UTENO', label: 'Ute/Northern' },
		{ val: 'UTESO', label: 'Ute/Southern Ute' },
		{ val: 'WICHITA', label: 'Wichita & Affiliated Tribes: Keechi,Waco,Tawakonie' },
		{ val: 'WINNEBA', label: 'Winnebago/NB' },
		{ val: 'YAKAMA', label: 'Yakama Nation' },
		{ val: 'YAQUI', label: 'Yaqui/Pascua' },
		{ val: 'OTHER', label: 'Another' }
	],
	heritages = [
		{ val: 'Am', label: 'American Indian or Alaska Native' },
		{ val: 'As', label: 'Asian' },
		{ val: 'Af', label: 'Black or African American' },
		{ val: 'Ha', label: 'Native Hawaiian or Other Pacific Islander' },
		{ val: 'Wh', label: 'White' },
		{ val: 'Cm', label: 'Cambodian', asian: true },
		{ val: 'Hm', label: 'Hmong', asian: true },
		{ val: 'La', label: 'Laotian', asian: true },
		{ val: 'Vi', label: 'Vietnamese', asian: true },
		{ val: 'Oai', label: 'Another Asian Identity', asian: true }
	],
	genders = [
		{ val: 'AGDR', label: 'Agender' },
		{ val: 'CMAN', label: 'Cisgender man' },
		{ val: 'CWOM', label: 'Cisgender woman' },
		{ val: 'GDRQ', label: 'Genderqueer' },
		{ val: 'GDRF', label: 'Genderfluid' },
		{ val: 'NONC', label: 'Nonbinary (not transgender)' },
		{ val: 'NONT', label: 'Nonbinary (transgender)' },
		{ val: 'TRM', label: 'Trans man' },
		{ val: 'TRW', label: 'Trans woman' },
		{ val: 'TWS', label: 'Two Spirit' },
		{ val: 'UNK', label: 'Unknown/questioning' },
		{ val: 'ULST', label: 'A gender not listed here' },
		{ val: 'NANS', label: 'Prefer not to say' }
	],
	activity_categories = [
		{ val: 'ACAD', label: 'Academic' },
		{ val: 'ART', label: 'Art' },
		{ val: 'ATH', label: 'Athletics' },
		{ val: 'CORT', label: 'Career Oriented' },
		{ val: 'COMS', label: 'Community Service (Volunteer)' },
		{ val: 'CMPT', label: 'Computer/Technology' },
		{ val: 'CULT', label: 'Cultural' },
		{ val: 'DANC', label: 'Dance' },
		{ val: 'DBSP', label: 'Debate/Speech' },
		{ val: 'ENV', label: 'Environmental' },
		{ val: 'FRSP', label: 'Family Responsibilities' },
		{ val: 'FEX', label: 'Foreign Exchange' },
		{ val: 'FLAN', label: 'Foreign Language' },
		{ val: 'INT', label: 'Internship' },
		{ val: 'JPUB', label: 'Journalism/Publication' },
		{ val: 'JROT', label: 'Junior R.O.T.C.' },
		{ val: 'LGBT', label: 'LGBT' },
		{ val: 'MUSI', label: 'Music: Instrumental' },
		{ val: 'MUSV', label: 'Music: Vocal' },
		{ val: 'REL', label: 'Religious' },
		{ val: 'RES', label: 'Research' },
		{ val: 'ROB', label: 'Robotics' },
		{ val: 'SPRT', label: 'School Spirit' },
		{ val: 'SCIM', label: 'Science/Math' },
		{ val: 'SOCJ', label: 'Social Justice' },
		{ val: 'SGVP', label: 'Student Govt/Politics' },
		{ val: 'THDR', label: 'Theater/Drama' },
		{ val: 'OTH', label: 'Other Club/Activity' }
	],
	legal_genders = [
		{ label: 'Female', val: 'F' },
		{ label: 'Male', val: 'M' },
		{ label: 'Another legal sex', val: 'X' }
	];
