import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row, Table } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { SENTRY, adminUrl } from 'components/modules/_misc';
import { APP_TYPES, has_fee, formalizeAppType, calculateProgressbar } from 'components/modules/app';
import { CAMPUS_TITLES, CAMPUS_CODES, DIRECT_ADMIT_CAMPUS_TIERS } from 'components/modules/campus';
import { formatDateTime } from 'components/modules/date';
import { nextAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { is_app_open, getADirectAdmitTerm } from 'components/modules/term';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import API from 'components/api';

const imgError = require('assets/img/exclamation-triangle-red.png');

class MyAccount extends Component {
	constructor(props) {
		super(props);

		const { apps, direct_admit, location } = props;

		this.state = {
			bypass_modal: false,
			active_tab: direct_admit?.user_id ? 'direct-admit' : 'app'
		};

		if (location.state?.tab) this.state.active_tab = location.state.tab;

		this.use_tabs = true; // tms, 5/9/24:  this will probably get more complicated later
		this.broken_app_ids = Object.keys(apps).filter(_app_id => {
			let _app = apps[_app_id],
				{ json_obj } = _app;

			return !_app.date_submitted && json_obj.application_modifier !== 'DA' && (!_app.app_type || !_app.campus);
		});
	}

	componentDidMount() {
		this._mounted = true;

		const { setAppId, obj_bypass } = this.props;

		setAppId('');

		if (obj_bypass.field_application_type) this.setState({ bypass_modal: true });
	}

	componentDidUpdate(prevProps) {
		const { app_id, app, direct_admit, navigate } = this.props;

		if (this._mounted) {
			if (app_id && !prevProps.app_id) {
				if (this.pay_later) {
					navigate('/payment', { state: { later: true } });
				} else {
					const tgt = this.force_nav_to_campus_selection ? '/direct-admit/select-campus' : nextAppPage(app);

					navigate(tgt);
				}
			}

			if (!direct_admit?.id && prevProps.direct_admit?.id) navigate('/link-direct-admit');
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	onOpenApp = _app_id => {
		const { terms, setAppId } = this.props;

		this.pay_later = false;
		this.force_nav_to_campus_selection = false;
		setAppId(_app_id, terms);
	};

	onPayApp = _app_id => {
		const { terms, setAppId } = this.props;

		this.pay_later = true;
		this.force_nav_to_campus_selection = false;
		setAppId(_app_id, terms, true);
	};

	onConfirmDeleteApp = application_id => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm-delete-prompt'>
						<h2 className='uwred'>Delete Application</h2>
						<p>Are you sure you want to delete this application?</p>
						<Row>
							<hr />
						</Row>
						<Button bsStyle='info' className='back-btn' fill onClick={onClose}>
							Cancel
						</Button>
						<Button
							bsStyle='info'
							className='save-btn'
							fill
							onClick={() => {
								this.onDeleteApp(application_id);
								onClose();
							}}>
							Delete Application
						</Button>
					</div>
				);
			}
		});
	};

	onDeleteApp = _app_id => {
		const { app_id, setAppId, deleteApp, captureError } = this.props;

		deleteApp(_app_id);

		if (app_id === _app_id) setAppId(''); // if you are deleting the application that is currently in state, remove it from the state

		this.getAPI()
			.deleteApplication(_app_id)
			.then(resp => {
				if (resp.result !== 'success') {
					SENTRY.set_tag('triggering_app_id', _app_id);
					throw new Error('error calling deleteApplication');
				}
			})
			.catch(ex => captureError(ex));
	};

	renderTermBypassModal = () => {
		let { navigate, obj_bypass, removeBypassObject } = this.props,
			{ bypass_modal } = this.state;

		return (
			<Modal show={bypass_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Term Bypass</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>You&apos;ve been invited to create the following application to a closed term.</p>
					<ul>
						<li>
							<strong>Campus</strong>:&emsp;&emsp;{obj_bypass.field_campus.title}
						</li>
						{!!(obj_bypass.field_specific_term || {}).title && (
							<li>
								<strong>Term</strong>:&emsp;&emsp;{obj_bypass.field_specific_term.title}
							</li>
						)}
						<li>
							<strong>Type</strong>:&emsp;&emsp;{APP_TYPES[obj_bypass.field_application_type]}
						</li>
					</ul>

					<p>Do you want to create that application now?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => {
							this.setState({ bypass_modal: false });
							removeBypassObject();
						}}>
						Cancel
					</Button>

					<Button bsStyle='info' className='save-btn' fill onClick={() => navigate('/select-modifier')}>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderActions = (_app_id, submitted, direct_admit) => {
		let { apps, terms } = this.props,
			_app = apps[_app_id],
			{ json_obj } = _app,
			record_type = direct_admit ? 'Direct Admit Form' : 'App',
			actions = [];

		if (submitted) {
			if (this.broken_app_ids.includes(_app_id)) {
				actions.push(
					<a id={`tooltip|${_app_id}`} key={`error|${_app_id}`}>
						<img src={imgError} height='18px' alt='Error' />
						<p className='pDashboard'>Error</p>
					</a>
				);
			} else {
				if (has_fee(_app) && _app.paid !== '1') {
					if (is_app_open(json_obj, terms, true)) {
						actions.push(
							<Button
								key={`pay|${_app_id}`}
								bsStyle='info'
								aria-label='Pay for Application'
								onClick={() => this.onPayApp(_app_id)}
								className=' change-btn btn-xs'>
								<img
									alt='editIcon'
									className='imgDashboard'
									height='18px'
									width='18px'
									src={_svg.Pay}
								/>
								<p className='pDashboard'>Pay</p>
							</Button>
						);
					} else if (!direct_admit) {
						actions.push(
							<p key={`mail|${_app_id}`} className='pDashboard uwRedText block'>
								Mail Payment to Campus
							</p>
						);
					}
				}

				actions.push(
					<Button
						key={`view|${_app_id}`}
						bsStyle='info'
						aria-label='View Application PDF'
						onClick={() => window.open(`${adminUrl()}sv_application_pdf/${_app_id}`, '_blank')}
						className=' change-btn btn-xs'>
						<img alt='editIcon' className='imgDashboard' height='18px' width='18px' src={_svg.View} />
						<p className='pDashboard'>{record_type}</p>
					</Button>
				);

				actions.push(
					<Button
						key={`download|${_app_id}`}
						bsStyle='info'
						aria-label='Download Next Steps PDF'
						onClick={() => window.open(`${adminUrl()}next_steps_pdf/${_app_id}`, '_blank')}
						className=' change-btn btn-xs'>
						<img alt='editIcon' className='imgDashboard' height='18px' width='18px' src={_svg.View} />
						<p className='pDashboard'>Next Steps</p>
					</Button>
				);
			}
		} else {
			actions.push(
				is_app_open(json_obj, terms) ? (
					<Button
						key={`edit|${_app_id}`}
						bsStyle='info'
						onClick={() => this.onOpenApp(_app_id)}
						className='editBtn btn-xs btn btn-info'>
						<img alt='editIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Edit} />
						<p className='pDashboard'>Edit</p>
					</Button>
				) : (
					<p className='pDashboard' key={`closed|${_app_id}`}>
						{record_type} Closed
					</p>
				)
			);

			actions.push(
				<Button
					key={`delete|${_app_id}`}
					bsStyle='info'
					onClick={() => this.onConfirmDeleteApp(_app_id)}
					className='deleteBtn btn-xs'>
					<img alt='deleteIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Delete} />
					<p className='pDashboard'>Delete</p>
				</Button>
			);
		}

		return actions;
	};

	renderAppList = (submitted, direct_admit) => {
		let { apps } = this.props,
			headers = [],
			record_type = direct_admit ? 'Direct Admit forms' : 'applications',
			_app_ids = Object.keys(apps).filter(_app_id => {
				let _app = apps[_app_id],
					{ json_obj } = _app;

				return !!_app.date_submitted === submitted && (json_obj.application_modifier === 'DA') === direct_admit;
			});

		if (submitted) headers.push(direct_admit ? 'ADMIT FORM ID' : 'APP ID');
		headers.push(direct_admit && !submitted ? 'CAMPUSES' : 'CAMPUS');
		if (!direct_admit) {
			headers.push('APP TYPE');
		} else if (!submitted) {
			headers.push('');
		}
		headers.push('TERM');
		headers.push(submitted ? 'SUBMITTED DATE' : 'UPDATED DATE');
		if (!direct_admit && submitted) headers.push('PAID');
		headers.push('ACTIONS');

		return (
			<Row className='rowMargin'>
				<h5>
					{submitted ? 'submitted ' : 'in progress '}
					{record_type}
				</h5>

				<Table className='table'>
					<thead>
						<tr>
							{headers.map(str => (
								<th key={str} scope='col'>
									{str}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{!_app_ids.length && (
							<tr>
								<td colSpan={headers.length}>
									{submitted
										? `You have no completed ${record_type}.`
										: `You have no ${record_type} in progress.`}
								</td>
							</tr>
						)}

						{_app_ids.map(_app_id => {
							let cells = [],
								_app = apps[_app_id],
								{ json_obj } = _app,
								percentComplete = calculateProgressbar(json_obj);

							if (submitted) {
								cells.push(_app_id.toUpperCase());
							}

							if (!submitted && !direct_admit) {
								cells.push(
									<>
										{_app.campus} <span className='completion-percentage'>{percentComplete}%</span>
									</>
								);
							} else if (submitted) {
								cells.push(_app.campus);
							} else {
								const campus_codes = Object.keys(_app.json_obj.initial_information.direct_admit);
								cells.push(campus_codes.map(code => CAMPUS_TITLES[code]).join(', '));
							}

							if (!direct_admit) {
								cells.push(formalizeAppType(json_obj));
							} else if (!submitted) {
								cells.push(<span className='completion-percentage'>{percentComplete}%</span>);
							}

							if (direct_admit) {
								const termYear = _app.term.match(/\d+/g)[0];
								cells.push(`Fall ${termYear} (classes start September ${termYear})`);
							} else {
								cells.push(_app.term);
							}

							if (submitted) {
								cells.push(formatDateTime(_app.date_submitted, true, true, true));
							} else if (this.broken_app_ids.includes(_app_id)) {
								cells.push(
									<a id={`tooltip|${_app_id}`}>
										<img src={imgError} height='18px' alt='Error' />
										<p className='pDashboard'>Error</p>
									</a>
								);
							} else {
								cells.push(formatDateTime(_app.date_last_updated, true, true, true));
							}

							if (!direct_admit && submitted) {
								let icons = {
										paid: (
											<img
												alt='appFeePaidIcon'
												height='24px'
												width='24px'
												src={_svg.AppFeePaid}
											/>
										),
										unpaid: (
											<img
												alt='appFeePaidIcon'
												height='24px'
												width='24px'
												src={_svg.AppFeeNotPaid}
											/>
										)
									},
									txt = 'Unknown';

								if (has_fee(_app)) {
									txt = _app.paid === '1' ? 'Fee Paid' : 'Not Paid';
								} else {
									txt = _app.application_fee_waiver_id > -1 ? 'Fee Waived' : 'Fee Exempt';
								}

								cells.push(
									<>
										{icons[_app.paid === '1' ? 'paid' : 'unpaid']}
										<p className='feePaidText'>{txt}</p>
									</>
								);
							}

							cells.push(this.renderActions(_app_id, submitted, direct_admit));

							return (
								<tr key={_app_id}>
									{cells.map((cell, i) => (
										<td key={`${_app_id}|${i}|${submitted}|${direct_admit}`}>{cell}</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Row>
		);
	};

	renderDirectAdmitCampusList = () => {
		const { direct_admit } = this.props,
			{ active_tab } = this.state;

		if (active_tab === 'direct-admit' && direct_admit?.user_id) {
			const applicant_tier = Number(direct_admit.tier.substr(1)),
				tierMet = code => {
					const i = DIRECT_ADMIT_CAMPUS_TIERS.findIndex(arrTier => arrTier.includes(code));
					return i > -1 && i < applicant_tier;
				},
				arrCampuses = Object.keys(CAMPUS_CODES)
					.map(code => {
						let arr = [];

						if (tierMet(code)) arr.push(code);
						CAMPUS_CODES[code].forEach(branch_code => {
							if (tierMet(branch_code)) arr.push(branch_code);
						});

						if (arr.length) return <li key={code}>{arr.map(_code => CAMPUS_TITLES[_code]).join('; ')}</li>;
					})
					.filter(x => !!x);

			if (arrCampuses.length) {
				return (
					<>
						<h4>Direct Admit Offers</h4>
						<ul>{arrCampuses}</ul>
					</>
				);
			}
		}
	};

	render() {
		let { navigate, direct_admit, apps, terms, unsetDirectAdmit, setAppId } = this.props,
			{ active_tab } = this.state,
			direct_admit_open = !!getADirectAdmitTerm(terms),
			txt_direct_admit_explain = '',
			primary_button,
			tabs = [
				<div
					key='trad-app-tab'
					className={`tab-header ${active_tab === 'app' ? 'active' : ''}`}
					onClick={() => this.setState({ active_tab: 'app' })}>
					Traditional Applications
				</div>,
				<div
					key='direct-admit-tab'
					className={`tab-header ${active_tab === 'direct-admit' ? 'active' : ''}`}
					onClick={() => this.setState({ active_tab: 'direct-admit' })}>
					Direct Admit
				</div>
			];

		if (active_tab === 'direct-admit') {
			if (!direct_admit_open) {
				txt_direct_admit_explain = 'Direct Admit forms will be accepted beginning August 1.';
			} else if (!direct_admit?.user_id) {
				txt_direct_admit_explain =
					'Are you a high school student participating in the Direct Admit Wisconsin program?';
			}
		}

		if (direct_admit?.user_id) tabs.reverse();

		if (active_tab === 'direct-admit') {
			if (direct_admit_open) {
				if (direct_admit?.user_id) {
					const wip_direct_admit_app_id = Object.keys(apps).find(_app_id => {
						const { app_type, date_submitted } = apps[_app_id];
						return app_type === 'DA' && !date_submitted;
					});

					primary_button = (
						<input
							type='button'
							className='btn-general save-btn'
							onClick={() => {
								if (wip_direct_admit_app_id) {
									this.force_nav_to_campus_selection = true;
									setAppId(wip_direct_admit_app_id, terms);
								} else {
									navigate('/direct-admit/select-campus');
								}
							}}
							value='Direct Admit Form'
						/>
					);
				} else {
					primary_button = (
						<input
							type='button'
							className='btn-general save-btn primary link-da'
							onClick={() => {
								if (direct_admit?.id) {
									unsetDirectAdmit();
								} else {
									navigate('/link-direct-admit');
								}
							}}
							value='Link your Direct Admit record'
						/>
					);
				}
			}
		} else {
			primary_button = (
				<input
					type='button'
					onClick={() => navigate('/select-modifier')}
					className='btn-general save-btn'
					value='Start Application'
				/>
			);
		}

		return (
			<>
				{this.renderTermBypassModal()}

				<Card
					className='my-account-container'
					noFooter={true}
					content={
						<form>
							<Row className='my-account-start-app-row'>
								<h3>MY ACCOUNT</h3>

								{!this.use_tabs && primary_button}
							</Row>

							{this.use_tabs ? (
								<>
									<div className='tab-wrapper'>{tabs}</div>
									<div className='tabs'>
										{!!txt_direct_admit_explain && (
											<>
												<p className='da-explain'>{txt_direct_admit_explain}</p>
												{!!direct_admit?.user_id && <hr />}
											</>
										)}
										<div className='btn-wrapper'>
											{primary_button}
											{this.renderDirectAdmitCampusList()}
										</div>

										{!(!direct_admit?.user_id && active_tab === 'direct-admit') && (
											<>
												{this.renderAppList(false, active_tab === 'direct-admit')}
												{this.renderAppList(true, active_tab === 'direct-admit')}
											</>
										)}
									</div>
								</>
							) : (
								<>
									{this.renderAppList(false, false)}
									{this.renderAppList(true, false)}
								</>
							)}

							{this.broken_app_ids.map(_app_id => {
								const _app = apps[_app_id];

								return (
									<Tooltip
										key={`tooltip|${_app_id}`}
										anchorId={`tooltip|${_app_id}`}
										className='tooltipContainer'
										delayHide={1000}
										effect='solid'
										content={
											<p>
												{_app.date_submitted
													? "We have a question on your application and need a bit more information from you. Please email contact@go.wisconsin.edu with the email and phone number from your account, along with the campus you're applying to."
													: 'Your application is missing crucial information. Please consider deleting this application and starting over. We apologize for any inconvenience.'}
											</p>
										}
									/>
								);
							})}
						</form>
					}
				/>
			</>
		);
	}
}

const mapStateToProps = state => {
		const {
			email,
			support_student_id,
			apps,
			app_id,
			first_name,
			last_name,
			obj_bypass,
			campus_admin,
			direct_admit
		} = state.user;

		return {
			email: email,
			support_student_id: support_student_id,
			terms: state.global.terms,
			apps: apps,
			app: app_id && apps[app_id] ? apps[app_id] : {},
			app_id: app_id,
			first_name: first_name,
			last_name: last_name,
			obj_bypass: obj_bypass,
			campus_admin: campus_admin,
			direct_admit: direct_admit
		};
	},
	mapDispatchToProps = dispatch => ({
		deleteApp: id => dispatch(UserActions.deleteApp(id)),
		setAppId: (id, terms, for_pay) => dispatch(UserActions.setAppId(id, terms, for_pay)),
		removeBypassObject: () => dispatch(UserActions.applyBypassObject()),
		captureError: err => dispatch(GlobalActions.captureError(err)),
		unsetDirectAdmit: () => dispatch(UserActions.setDirectAdmit({}))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
