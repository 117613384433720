import React, { Component } from 'react';

import _svg from 'components/modules/svg';

class ContactUsInfo extends Component {
	render() {
		return (
			<div className='text-center'>
				<img className='uws-logo' src={_svg.UWS_std} alt='Universities of Wisconsin logo' />
				<div>
					<h3>(Go Wisconsin)</h3>
					<h3>
						Call: <a href='tel:+1-800-442-6459'>1-800-442-6459</a> or{' '}
						<a href='tel:+1-608-263-4567'>(608) 263-4567</a>
					</h3>
					<h3>Wisconsin Relay: 711</h3>
					<h3>
						Email: <a href='mailto:contact@go.wisconsin.edu'>contact@go.wisconsin.edu</a>
					</h3>
					<h3>Hours: 8:00 am to 4:30 pm (cst) Monday-Friday</h3>
				</div>
			</div>
		);
	}
}

export default ContactUsInfo;
