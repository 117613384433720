import React, { Component } from 'react';
import { Card } from 'components/Card/Card';

import ContactUsInfo from 'views/Public/shared-content/contact-us-info';

class ContactUs extends Component {
	render() {
		return <Card title='GO WISCONSIN CONTACT PAGE' content={<ContactUsInfo />} />;
	}
}

export default ContactUs;
