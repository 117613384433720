import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'components/Wrappers/withRouter';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { GlobalActions } from 'reducers/global';

class OnError extends Component {
	render() {
		const { navigate, captureError, email, err } = this.props,
			network_err = err.message?.includes('Network Error');

		return (
			<div className='wrapper noSidebarWrapper' role='main'>
				<div id='main-panel' className='main-panel noSidebarPanel' ref='mainPanel'>
					<Card
						content={
							network_err ? (
								<>
									<h1>Connection lost</h1>
									<p>Looks like we lost you there. Are you still online?</p>
								</>
							) : (
								<>
									<h1>OOPS!</h1>

									<p>Whatever that was, it certainly wasn&apos;t supposed to happen.</p>

									<p>
										If this your first time seeing this page, please refresh the page and try again.
										If this issue persists, please contact 1-800-442-6459 or{' '}
										<a href='mailto:contact@go.wisconsin.edu'>contact@go.wisconsin.edu</a> for
										assistance.
									</p>
								</>
							)
						}
						buttons={
							email && !network_err ? (
								<>
									<Button
										bsStyle='info'
										className='back-btn col-centered'
										fill
										onClick={() => {
											navigate('/my-account');
											captureError();
										}}>
										My Account
									</Button>
									<Button
										bsStyle='info'
										className='back-btn col-centered'
										fill
										onClick={() => {
											navigate('/', { state: { logging_out: true } });
											captureError();
										}}>
										Log me Out
									</Button>
								</>
							) : (
								<Button
									bsStyle='info'
									className='back-btn col-centered'
									fill
									onClick={() => {
										navigate('/');
										captureError();
									}}>
									Home
								</Button>
							)
						}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
		email: state.user.email,
		err: state.global.err
	}),
	mapDispatchToProps = dispatch => ({
		captureError: () => dispatch(GlobalActions.captureError())
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnError));
